import { APP_PREFIX } from "helpers/configs";
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PageLoader from "./shared-components/Loader";

export const AppViews = () => {
  document.body.style.backgroundColor = "#FFF";

  return (
    <Suspense fallback={(<PageLoader />)}>
      <Switch>
        <Route path={`${APP_PREFIX}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX}/events`} component={lazy(() => import(`./marathon-events`))} />
        <Route path={`${APP_PREFIX}/eventlists`} component={lazy(() => import(`./event`))} />
        <Redirect from={`${APP_PREFIX}`} to={`${APP_PREFIX}/home`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
