import React from "react"
import { Switch, Route, } from "react-router-dom"
import AuthViews from "views/auth"

export const AuthLayout = () => {
    return (
        <div className="mn-auth">
            <Switch>
                <Route path="" component={AuthViews} />
            </Switch>
        </div>
    )
}

export default AuthLayout;