import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space, Avatar, message } from 'antd';
import { Row, Col } from "antd"
import Cookies from 'js-cookie';
import { APP_PREFIX } from 'helpers/configs';
import { Link } from 'react-router-dom';
import { useAdminName } from 'hooks/Event';
import Logo from "../../assets/logo.svg"
import logout from "../../assets/log-out.svg";



const Header = () => {

    const { data: admin } = useAdminName();

    const handleLogout = () => {
        Cookies.remove('marathon_token');
        localStorage.clear();
        message.success('Logged Out Successfully !!!');
        window.location = "/";
    }


    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <div onClick={() => handleLogout()} className="log-out">
                            <img src={logout} alt="../" />
                            Log Out
                        </div>
                    ),
                }
            ]}
        />
    );

    return (
        <Row className="header">
            <Col md={2} xs={14}>
                <Link to={`${APP_PREFIX}/home`}>
                    <img src={Logo} className="logo-home" />
                </Link>
            </Col>
            <Col md={19}>

            </Col>
            <Col md={3} xs={10} className="txt-right">
                <Dropdown overlay={menu}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space className='hover-dropdown'>

                            <Avatar>{admin?.data?.firstLetter}</Avatar>  {admin?.data?.loginId}
                            <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
            </Col>
        </Row>
    )
}

export default Header