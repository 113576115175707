import { AUTH_PREFIX } from "helpers/configs";
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

export const AuthViews = () => {
    return (
        <Suspense fallback={"Loading...."}>
            <Switch>
                <Route path={`${AUTH_PREFIX}/login`} component={lazy(() => import(`./login`))} />
                <Route path={`${AUTH_PREFIX}/forgot-password`} component={lazy(() => import(`./forgot-password`))} />
                <Route path={`${AUTH_PREFIX}/reset-password`} component={lazy(() => import(`./reset-password`))} />
                <Redirect from={`${AUTH_PREFIX}`} to={`${AUTH_PREFIX}/login`} />
            </Switch>
        </Suspense>
    )
}

export default AuthViews;