import { message } from "antd";
import axios from "axios";

// const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = localStorage.getItem("marathon_token");


const client = axios.create({
    baseURL: "https://marathonapi.corpfield.com/",
    timeout: TIMEOUT,
    headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${TOKEN}`,
    }
});

client.interceptors.request.use(function (config) {
    return config;
});
client.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            localStorage.clear();
            window.location = "/";
        } else if (error.response.status !== 200) {
            console.log(error);
            message.error(error.response.data.data);
        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);

const adminName = async () => {
    const { data } = await client.get(`admin/adminName`);
    return data;
}
const homePastUpcomingEvent = async (formData) => {
    const { data } = await client.post(`admin/homePage`, formData);
    return data;
}
const changeEventToggle = async (formData) => {
    const { data } = await client.post(`admin/toggle`, formData);
    return data;
}
const viewPastUpcomingEvent = async (formData) => {
    const { data } = await client.post(`admin/event/pastOrUpcomingEvents`, formData);
    return data;
}
const eventPastView = async (id) => {
    const { data } = await client.post(`admin/event/pastEvents/${id}`);
    return data;
}
const eventUpcomingView = async (id) => {
    const { data } = await client.post(`admin/event/currentOrUpcomingEvents/${id}`);
    return data;
}
const viewParticipantDetails = async (formData) => {
    const { data } = await client.post(`admin/participant/participantDetails`, formData);
    return data;
}
const getParticularParticpant = async (formData) => {
    const { data } = await client.post(`admin/participantIndividualRankDetails`, formData);
    return data;
}
const getMarthonDetails = async (formData) => {
    const { data } = await client.post(`admin/eventDetails/raceDetail/participantList`, formData);
    return data;
}
const getAllPartiPantDetails = async (formData) => {
    const { data } = await client.post(`admin/raceDetail/participantList`, formData);
    return data;
}
const getParticipantReport = async (id, id1) => {
    const { data } = await client.post(`admin/downloadParticipantList?eventId=${id}&categoryId=${id1}`);
    return data;
}

export {
    adminName,
    homePastUpcomingEvent,
    viewPastUpcomingEvent,
    eventPastView,
    eventUpcomingView,
    changeEventToggle,
    viewParticipantDetails,
    getParticularParticpant,
    getMarthonDetails,
    getAllPartiPantDetails,
    getParticipantReport
};