import Loader from "../../../assets/man.gif";

export const PageLoader = () => {
    return (
        <div >
            <img src={Loader} alt="..." className="loader" />
        </div>
    )
}

export default PageLoader;