import Cookies from "js-cookie";
import { useMutation, useQuery } from "react-query";
import * as api from "services/EventServices";

export const useHomePastUpComingEvent = () => {
    return useMutation((formData) => api.homePastUpcomingEvent(formData));
}
export const useEventToggle = () => {
    return useMutation((formData) => api.changeEventToggle(formData));
}
export const useViewPastUpComingEvent = () => {
    return useMutation((formData) => api.viewPastUpcomingEvent(formData));
}
export const useViewParticipantDetails = () => {
    return useMutation((formData) => api.viewParticipantDetails(formData));
}
export const useGetParticularParticipant = () => {
    return useMutation((formData) => api.getParticularParticpant(formData));
}
export const useGetMarathonDetails = () => {
    return useMutation((formData) => api.getMarthonDetails(formData));
}
export const usePastEventView = (id) => {
    return useQuery(["EventView ", id], () => api.eventPastView(id), {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useUpcomingEventView = (id) => {
    return useQuery(["UpComingEvent ", id], () => api.eventUpcomingView(id), {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useAdminName = () => {
    return useQuery("AdminName", () => api.adminName(), {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}
export const useEventPaticipantDetails = () => {
    return useMutation((formData) => api.getAllPartiPantDetails(formData));
}
export const useDownloadReport = () => {
    return useMutation((id) => api.getParticipantReport(id, Number(Cookies.get("cate_id"))));
}