import React from 'react'
import { Switch, Route, } from "react-router-dom"
import AppViews from 'views/app'
import Header from 'layouts/utility/Header'
import Footer from 'layouts/utility/Footer'

export const AppLayout = () => {

    return (
        <div className="sm-app">
            <Header />
                <Switch>
                    <Route path="" component={AppViews} />
                </Switch>
            <Footer />
        </div>
    )
}

export default AppLayout;